import React from "react"
import { graphql } from "gatsby"

import { Password as Page } from "../components/Password/Password"

export const query = graphql`
  query {
    page: sanitySettingMaintenance {
      title
      backgroundImage: backgroundImage {
        alt
        asset {
          fluid(maxWidth: 2000) {
            ...GatsbySanityImageFluid
          }
        }
      }
      content: _rawContent(resolveReferences: { maxDepth: 10 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

export default ({ data, ...props }) => <Page {...props} {...data} />
