import React, { useRef } from "react"

import { useLanguage } from "../../hooks/useLanguage"
import { useMaintenance } from "../../hooks/useMaintenance"
import { useSanity } from "../../hooks/useSanity"

export const withPassword = Component => ({ name = `Password`, location, page, ...props }) => {
  const { validatePassword, data, setData, error } = useMaintenance(location)
  const { textNormaliser } = useSanity()

  const locales = useLanguage(`password`)
  const field = useRef()

  const content = {
    ...page,
    content: textNormaliser(page?.content),
    image: {
      ...(page?.backgroundImage?.image?.asset || {}),
      alt: textNormaliser(page?.backgroundImage?.alt),
    },
  }

  const handleSubmit = event => {
    event.preventDefault()
    validatePassword()
  }

  const handleChange = ({ target: { type, name, value, checked } }) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  Component.displayName = name
  return (
    <Component
      {...props}
      content={content}
      data={data}
      error={error}
      field={field}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      locales={locales}
    />
  )
}
